import React, { useCallback } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { RegisterLayout } from '../../components/register-layout.component';
import StepInstruction from '../../components/step-instruction.component';
import { FooterNavigation } from '../../components/footer-navigation.component';

const itemsInBox = [
    { name: "1. 唾液採取容器セット", img: "/img/items/item-in-box-1.jpg" },
    { name: "2. 吸水シート", img: "/img/items/item-in-box-2.jpg" },
    { name: "3. 緩衝材", img: "/img/items/item-in-box-3.jpg" },
    { name: "4. 密封袋（バイオパウチ）", img: "/img/items/item-in-box-4.jpg" },
    { name: "5. 返信用封筒（レターパック）", img: "/img/items/item-in-box-5.jpg" }
];

export default function Index() {
    const { navigate, t } = useI18next();
    const onNextClick = useCallback(e => {
        navigate('/register');
    }, [ navigate ]);
    return (
        <RegisterLayout className="create-account create-account--check-items" backLocation="/register">
            <div className="section section--check-items animateIn">
                <h1 className="t-heading1 t-black">{t('Check the contents of the kit')}</h1>
                <p className="t-sans t-gray9">これから検査キットの登録に進みます。<br/>
                登録の前に、内容物の確認をしてください。内容物に不備等ございましたら、お問い合わせください。<br/>
                お問い合わせ：0120-294-998<br/>
                ※検査所で検査をされる方は、キット内容が異なります。係員の指示に従い、専用のキットを受け取ってください。</p>
            </div>
            <div className="section section--steps section--check-items animateIn">
                {itemsInBox.map((item, index) => (
                    <StepInstruction key={index} step={item.name} stepImg={item.img} />
                ))}
            </div>
            <FooterNavigation
                steps={4}
                activeStep={0} 
                label={t('Next')}
                onClick={onNextClick}
            />
        </RegisterLayout>
    );
}